<!--
 * @Author: lbh
 * @Date: 2021-04-23 10:59:31
 * @LastEditors: lbh
 * @LastEditTime: 2021-04-29 14:34:53
 * @Description: 聯絡我們
-->
<template>
  <div
    class="contact t-body"
    :style="`min-height:${window.bodyH}px;`"
  >
    <selfHeaderN
      ad="contact"
      :type="window.type"
    />
    <div
      class="s-body"
      :class="window.type=='1000' ? '' : 's-body_1'"
    >
      <selfBackground
        :backH="window.backH"
        title="聯絡我們"
        :type="1"
        :image="getImgPath('telsubbanner.jpg')"
      />
      <!-- 公司地址啥的 -->
      <div class="gingersoft-add">
        <div class="title">薑軟件有限公司</div>
        <div
          class="items"
          :class="window.type != '1000' ? 'items_1' : ''"
        >
          <div class="items-c">
            <div class="item">
              <i class="el-icon-phone_ iconfont theme_color"></i>
              <span>聯繫電話（香港）：（852）8200 2022</span>
            </div>
            <div class="item">
              <i class="el-icon-place iconfont theme_color"></i>
              <span>單位地址：九龍青山道479-479A 麗昌工廠大廈6樓603B室</span>
            </div>
          </div>
          <div class="items-c">
            <div class="item">
              <i class="el-icon-email iconfont theme_color"></i>
              <span>
                郵箱：
                <a
                  class="theme_color"
                  href="mailto:info@gingersoft.com.hk"
                >info@gingersoft.com.hk</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <selfFooter :type="window.type" />
  </div>
</template>
<script>
import selfHeaderN from "@/components/header/headerN";
import selfFooter from "@/components/footer/footer";
import selfBackground from "@/components/commo/background";
import util from "@/js/utils/util";
export default {
  components: {
    selfHeaderN,
    selfFooter,
    selfBackground
  },
  data () {
    return {
      window: {
        type: '1000',
        bodyH: 0,
        backH: 0
      },
    }
  },
  mounted () {
    util.setTitle('聯絡我們 – 薑軟件有限公司');
    // 初始化窗口
    this.initWindow();
    // 監聽 窗口
    window.onresize = () => {
      // 1000 700
      this.initWindow();
    };
  },
  created () { },
  methods: {
    initWindow () {
      let W = util.W(), w = W.w, h = W.h;
      this.window.bodyH = h;
      this.window.backH = parseInt(w / 2.5);
      if (w < 700) {
        this.window.type = '600';
        this.window.backH = w / 0.7;
      } else if (w < 1000) {
        this.window.type = '900';
        this.window.backH = w / 0.7;
      } else {
        this.window.type = '1000';
      }
    },
    getImgPath (t) {
      return util.getImgPath(t);
    }
  }
}
</script>
<style lang="less" scoped>
.gingersoft-add {
  padding: 50px 10% 200px;
  .title {
    font-size: 25.5px;
    font-weight: 400;
  }
  .items_1 {
    flex-direction: column;
    .items-c{
      width:100% !important;
    }
  }
  .items {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    .items-c {
      width: 50%;
    }
    .item {
      margin-top: 15px;
      display: flex;
      align-items: center;
      i {
        font-size: 34px;
        margin-right: 15px;
      }
      a {
        text-decoration: none;
      }
    }
  }
}
</style>
